// @ts-nocheck
import React from "react";
import { TableCell, Typography } from "@material-ui/core";
import CustumMenus from "../../partials/CustumMenus";
import { userRole } from "./IntegratorsList";

export const generateGridData = (result) => {
  const gridColumns = [
    {
      name: "Name",
      alignPosition: "center",
      CellRenderer: (value) => {
        const { first_name, last_name } = value;
        return (
          <TableCell align="center">
            <Typography>{`${first_name} ${last_name}`}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Role",
      alignPosition: "center",
      CellRenderer: (value) => {
        const { role } = value;

        return (
          <TableCell align="center">
            <Typography>{userRole[role]}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Company",
      alignPosition: "center",
      CellRenderer: (value) => {
        const company = value.company;
        return (
          <TableCell align="center">
            <Typography>{company}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Email",
      alignPosition: "center",
      CellRenderer: (value) => {
        const email = value.email;
        return (
          <TableCell align="center">
            <Typography>{email}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Number of Keys",
      alignPosition: "center",
      CellRenderer: (value) => {
        const licenseCount = value.license_count;
        return (
          <TableCell align="center">
            <Typography>{licenseCount}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Action",
      alignPosition: "center",
      CellRenderer: (_, rowData, handlerEnableChange) => {
        return (
          <TableCell align="center">
            <CustumMenus
              element={rowData}
              handlerEnableChange={handlerEnableChange}
            />
          </TableCell>
        );
      },
    },
  ];

  const gridRows = result;

  return {
    gridColumns,
    gridRows,
  };
};
